import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';
import Image from 'components/common/Image';
import RacBasePlans from 'components/cards/RacBasePlans';
import RacBasePlansSummer from 'components/cards/RacBasePlansSummer';
import AwardsInfo from "components/cards/AwardsInfo";
import Faq from 'components/common/Faq';
import ProductHighlightNew from 'components/cards/ProductHighlightNew';

import { LinkModal } from 'kh-common-components';

import { modalContents } from 'constants/modal-content';
import faqDataOfferNew from 'constants/components/accordion-list/rac-ng-faq-new';
import { useSummerCampaignActive } from 'constants/hooks/marketingEventToggles';

import heroBanner from 'images/rac/natural-gas/kleenheat-rac-logo.png';

function RacPage() {
	
    return (
        <>
			<RacPageNew />
        </>
    );
}

function RacPageNew() {
	const isSummerCampaignActive = useSummerCampaignActive();

	return (
		<>
			<SEO title="Exclusive Kleenheat offer for RAC Members | Kleenheat" 
                 titleBespoke="true"
            	 description="RAC members can enjoy bigger savings on their natural gas with Kleenheat."
                 meta={[{name:"robots", content:'noindex'}]}
        	/>
			<Header />
			<LayoutMain>
				<LayoutPanel theme="mer-theme--cyan"
							 background="mer-bg--primary-cyan"
							 padding="mer-py-lg">
					<div className="container">
						<div className="row mer-panel align-items-center justify-content-around flex-lg-column">
							<div className="mer-panel__images col-10 col-sm-6 col-lg-5 col-xl-4 my-0 pt-0">
								<img src={heroBanner} 
									className="mer-panel__image kleenheat-rac-logo"
									alt="RAC logo" />
							</div>

							<div className="col-12 col-sm-10 col-lg-8 col-xl-7 pt-0">
								<div className="content text-center mer-text--size-lg">
									<h1 className="mer-panel__title mer-typography--headline2 my-0">RAC members save an additional 3% off natural gas usage charges.<sup>^</sup></h1>

									<div className="mer-panel__actions mer-button-group justify-content-center flex-column col-10 col-md-6 col-lg-5 mx-auto">
										<a href="/sign-up/?rac_member=%00"
											title="Sign up"
											className="mer-button mer-button--primary mr-0 mb-3">
											Sign up
										</a>
										<a href="#plans"
											title="View plans"
											className="mer-button mer-button--secondary">
											View plans
										</a>
									</div>

									<p className="mer-typography--body3 mt-4">
										<LinkModal 
											modalContents={modalContents}
											useModal="true"
											displayContent="^Terms and conditions apply"
											title="Kleenheat’s RAC partnership"
											modalTitle="Kleenheat’s RAC partnership"
											modalContentName="rac-ng-terms"
											okayButtonLabel="Close"
										/>
									</p>		
								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<ProductHighlightNew panelBackground="mer-bg--ui-light"
										panelPadding="mer-pt-lg" />

			{isSummerCampaignActive && 
				<RacBasePlansSummer id="pricing"
                          	  racOfferPromoTerms="rac-ng-terms" />
			}
			{!isSummerCampaignActive && 
				<RacBasePlans id="pricing"
                          	  racOfferPromoTerms="rac-ng-terms" />
			}
				<AwardsInfo
					panelBackground="mer-bg--gs-grey-lighter"
					panelPadding="mer-pt-de mer-pb-lg"
				/>

				<Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataOfferNew} />
			</LayoutMain>
			<Footer />
		</>
	)
}

export default RacPage;