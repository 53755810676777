import React, { useState, useEffect, createRef } from 'react';
import Axios from 'axios';
import Slider from 'react-slick';

import { LinkModal } from "kh-common-components";
import { modalContents } from "../../constants/modal-content";
import flowmoji50 from 'images/rac/natural-gas/50-flowmoji.svg';
import planWelcomeBonus from 'images/natural-gas/plan-bonus.png';
import Image from 'components/common/Image';

import 'styles/components/cards/rac-plans.css';
import "styles/pages/product-select-summer.css";

function RacBasePlans(props) {

    const [plansData, setPlansData] = useState(null);
    const [racMember, setRacMember] = useState('');

    const racOfferPromoDisc = parseInt(process.env.RAC_OFFER_PROMO_DISC);

    const carouselClass = 'mer-media-card-carousel mer-media-card-carousel-react container-fluid-constrained mer-slick-slider--height-equal';

    let mediaCardRef = [];

    const getPlanData = async () => {

        if (plansData !== null) {
            return;
        }

        try {
            const plans = await Axios.get(process.env.PRODUCTS_API);
            const reversed = plans.data.reverse();
            setPlansData(reversed);
        } catch (error) {
            console.log('Error getting plan details');
        }
    };

    const settings = {
        // rows: 0, // causes memory leak
        // lazyLoad: true,
        arrows: false,
        dots: true,
        infinite: false,
        initialSlide: 1,
        swipeToSlide: true,
        draggable: true,
        mobileFirst: true,
        transformEnabled: false,
        useTransform: false,

        speed: 800,
        slidesToShow: 3,

        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '100px',
  
        dotsClass: 'd-flex slick-dots',
        className: carouselClass,

        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '1px'
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
                {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '150px'
                }
            },
            {
                breakpoint: 639, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 550, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]
    };

    useEffect(() => {
        getPlanData();

        mediaCardRef.forEach(referenceItem => {
            if(referenceItem.current) {
                referenceItem.current.parentElement.classList.add('col', 'mer-card', 'mer-card-media', 'mdc-card');
            }
        });

        const queryParams = new URLSearchParams(window.location.search);
        const racMember = queryParams.get('rac_member');
        if (racMember) {
            setRacMember(racMember);
        }

    }, [getPlanData]);

    return(
        <>
            { Array.isArray(plansData) && plansData.length > 0 &&
                <section id="plans" 
                        className="mer-bg--ui-light mer-py-ju">
                    <div className="container">

                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-8">
                                <div className="content text-center">
                                    <h2>Natural gas pricing for RAC members</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Slider {...settings}>

                        {plansData.map((plan, index) => {
                            const newRef = createRef();
                            mediaCardRef.push(newRef);

                            let isStandard = plan.isStandard ? 'standard' : 'non-standard';
                            let discount = plan.isStandard ? plan.onTimePaymentDiscount : plan.discount;                            
                            let discountDecimalPlace = "";
                            let tcUrl = 'plan_details_and_terms_rac_' + plan.key;

                            if (plan.key == 2) {
                                discount = 35;
                                tcUrl += '_summer';
                            }
                            if (!Number.isNaN(discount)) {         
                                if (!Number.isInteger(discount)) { // if discount is a number with decimal places
                                    // split whole number and decimal place
                                    const discountInStr = discount.toString();
                                    const dotIndex = discountInStr.indexOf(".");
                                    discountDecimalPlace = discountInStr.substring(dotIndex, dotIndex + 2); // only keeping 1 decimal place                                    
                                }
                                discount = parseInt(discount) + racOfferPromoDisc; // whole number
                            }

                            return (
                                <div className={`mer-card-media-react__container mer-plan-card mer-card mdc-card text-center py-4 ${plan.key === '2' ? 'product-select-summer online-offer-only' : ''}`} key={index} ref={newRef}>
                                    {plan.key === '2' &&
                                        <h5 className="mer-bg--primary-blue mer-color--gs-white online-offer-only-sup">Online only offer<sup>^</sup></h5>
                                    }
                                    <h4 className="mer-plan-card__title">{plan.name}</h4>
                                    <p className="mer-plan-card__description">
                                        <span className="mer-plan-card__discount">
                                            {discount}
                                            {discountDecimalPlace !== "" && <span className="mer-plan-card__discount-decimal">.5</span>}
                                            <span className="mer-plan-card__discount-percent">%</span>
                                        </span>                                    
                                        <span className="mer-plan-card__discount-description">off gas usage charges {plan.onTimePaymentDiscount !== 0 ? "when you pay on time" : "for at least 12 months"}</span>
                                    </p>
                                    <div className={plan.key === '537' ? 'col-4 mx-auto mb-0 py-0' : 'col-8 mx-auto py-0 mb-1 mer-my-sm'}>
                                        <div className="mer-panel__images d-flex align-items-center align-self-center justify-content-center">
                                            {!plan.welcomeBonusAvailable &&    
                                                <Image className="mer-panel__image"
                                                        src="/icons/flowmoji/flowmoji-piggybank.svg"
                                                        alt="Flowmoji Piggybank"
                                                />
                                            }
                                            {plan.welcomeBonusAvailable &&                       
                                                <img src={planWelcomeBonus} 
                                                    className="mer-panel__image"
                                                    alt="Welcome Bonus" />
                                            }
                                        </div>
                                    </div>
                                    <p className={`mer-color--primary-blue mer-typography--headline6 mt-0 ${plan.key === '537' ? ' pb-2' : ' py-2'}`}>
                                        {plan.welcomeBonusAvailable && <>Your choice of $50 welcome bonus<sup>+</sup></> }
                                        {!plan.welcomeBonusAvailable && <>Just great value gas</> }
                                    </p>
                                    <ul className="mer-list mer-color--primary-navy mer-border--top mer-border--bottom mer-border--color-gs-lighter m-0 px-2 py-3 mb-4">
                                        {plan.requiresDirectDebit &&
                                        <>
                                            <li><strong>Direct debit</strong></li>
                                            <li><strong>Easy monthly instalments</strong></li>
                                            <li><strong>Email bills</strong></li>
                                        </>
                                        }
                                        {!plan.requiresDirectDebit &&
                                        <>
                                            <li><strong>Choice of payment method</strong></li>
                                            <li><strong>Quarterly bills</strong></li>
                                            <li><strong>Email or paper bills</strong></li>
                                        </>
                                        }
                                        <li>No setup costs</li>
                                        <li>No lock-in contracts</li>
                                    </ul>
                                    <div className="mx-4 text-center">
                                        <div className="content">
                                            <a href={`/sign-up/product/${plan.key}?rac_member=${racMember}`}
                                                className="mer-button mer-button--primary mer-button--size-full-width mb-3">
                                                <span className="mer-color--gs-white">Choose plan</span>
                                            </a>
                                        </div>
                                        <div className="mt-3">   
                                            <small className="ng-plan-details text-center mer-typography--body3">
                                                <LinkModal
                                                    modalContents={modalContents}
                                                    useModal="true"
                                                    title={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                    displayContent={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                    modalTitle={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                    modalContentName={`${tcUrl}`}
                                                    okayButtonLabel="Okay"
                                                />
                                            </small>
                                        </div>
                                    </div>
                                </div>
                        )})}

                    </Slider>

                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-8 col-md-6 col-lg-8">
                                <div className="text-center mer-mt-sm">
                                    <p className="mb-2 pb-0">
                                        <small>
                                            <LinkModal
                                                modalContents={modalContents}
                                                useModal="true"
                                                displayContent="View prices and fees for all natural gas customers"
                                                title="View prices and fees for all natural gas customers"
                                                modalTitle="View prices and fees for all natural gas customers"
                                                modalContentName="standard_prices_and_fees"
                                                okayButtonLabel="Close"
                                            />
                                        </small>
                                    </p>
                                    <p className="mer-color--primary-blue mer-font--weight-bolder mt-sm-2 mer-typography--headline5"><sup>^</sup>Offer available online only to new and moving customers. Offer ends 30 April 2025.</p>
                                    <p><small><LinkModal {...props}
                                        modalContents={modalContents}
                                        useModal="true"
                                        title="Welcome bonus terms and conditions"
                                        // linkClass=""
                                        displayContent={<span><sup>+</sup>Welcome bonus terms and conditions.</span>}
                                        modalTitle="Welcome bonus terms and conditions"
                                        modalContentName="summer-terms"
                                        okayButtonLabel="Okay"
                                    /></small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
);
}

export default RacBasePlans;